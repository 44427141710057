<template>
  <div :class="getClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  dropShadow?: boolean;
  noPadding?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  dropShadow: false,
  noPadding: false,
});

const getClasses = computed(() => {
  return {
    'bitts-container': true,
    'bitts-container__drop-shadow': props.dropShadow,
    'p-0': props.noPadding,
  };
});
</script>

<style lang="pcss">
.bitts-container {
  @apply border border-solid border-neutral-border shadow-component rounded-bts-lg bg-white p-16;
}

.bitts-container__drop-shadow {
  @apply shadow-component;
}
</style>
